import { getItem, removeItem, setItem, tokenPaths } from "./localStorage"

export const getStoredAuthToken = () => getItem(tokenPaths.authToken)

export const storeAuthToken = (token: string) => setItem(tokenPaths.authToken, token)

export const removeStoredAuthToken = () => removeItem(tokenPaths.authToken)

export const getStoredAuthTokenRevisor = () => getItem(tokenPaths.authTokenRevisor)

export const storeAuthTokenRevisor = (token: string) => setItem(tokenPaths.authTokenRevisor, token)

export const removeStoredAuthTokenRevisor = () => removeItem(tokenPaths.authTokenRevisor)

export const getStoredAuthTokenAdmin = () => getItem(tokenPaths.authTokenAdmin)

export const storeAuthTokenAdmin = (token: string) => setItem(tokenPaths.authTokenAdmin, token)

export const removeStoredAuthTokenAdmin = () => removeItem(tokenPaths.authTokenAdmin)


import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from './helpers'

export const PAGE_TAB = 'PageTab'
export const LL_PERIODS = 'llPeriods'
export const LOCATION_SUMS = 'locationSums'
export const DATE_SPECIFICATIONS = 'dateSpecifications'

const mainApi = createApi({
    reducerPath: 'mainApi',
    baseQuery,
    tagTypes: [PAGE_TAB, LL_PERIODS, LOCATION_SUMS, DATE_SPECIFICATIONS],
    endpoints: () => ({}),
    keepUnusedDataFor: 86400 * 30, // 1 month
})

export default mainApi

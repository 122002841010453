import { combineReducers } from 'redux'
import tabs, { TabsState } from './reducers/tabs'

import mainApi from './services/mainApi'

export interface RootState {
    mainApi: any
    tabs: TabsState
}

const appReducer = combineReducers({
    tabs,
    [mainApi.reducerPath]: mainApi.reducer,
})

export const RESET_STATE = 'RESET_STATE'

const rootReducer = (state: RootState | undefined, action: any) => {
    if (action.type === RESET_STATE) {
        state = undefined
    }

    return appReducer(state, action)
}

export default rootReducer

import { createSlice } from '@reduxjs/toolkit'
import { getItem, setItem, tokenPaths, removeItem } from '../../utils/localStorage'
import type { PayloadAction } from '@reduxjs/toolkit'

export enum AppTabs {
    Calendar = 'Calendar',
    Overview = 'Overview',
    Settings = 'Settings',
    Rule183 = 'Rule183',
}

export interface TabsState {
    currentTab: AppTabs
    isBackgroundLoading: boolean
}

export interface StoreCurrentTab {
    currentTab: AppTabs
}

export interface StoreIsBackgroundLoading {
    isBackgroundLoading: boolean
}

function storeValuesLocally(currentTab: AppTabs) {
    if (currentTab) {
        setItem(tokenPaths.currentTab, currentTab)
    } else {
        removeItem(tokenPaths.currentTab)
    }
}

function storeIsBackgroundLoading(isBackgroundLoading: boolean) {
    if (isBackgroundLoading) {
        setItem(tokenPaths.isBackgroundLoading, 'true')
    } else {
        setItem(tokenPaths.isBackgroundLoading, 'false')
    }
}

const initializeState = ({} = {}): TabsState => {
    const currentTab = getItem(tokenPaths.currentTab) || AppTabs.Calendar
    const localLoadingValue = getItem(tokenPaths.isBackgroundLoading) || null
    let isBackgroundLoading = false
    if (localLoadingValue === 'true') {
        isBackgroundLoading = true
    }
    return {
        currentTab,
        isBackgroundLoading,
    }
}

const slice = createSlice({
    name: 'tabs',
    initialState: initializeState(),
    reducers: {
        storeCurrentTab: (state: TabsState, action: PayloadAction<StoreCurrentTab>) => {
            storeValuesLocally(action.payload.currentTab)
            state.currentTab = action.payload.currentTab
        },
        setIsBackgroundLoading: (
            state: TabsState,
            action: PayloadAction<StoreIsBackgroundLoading>
        ) => {
            storeIsBackgroundLoading(action.payload.isBackgroundLoading)
            state.isBackgroundLoading = action.payload.isBackgroundLoading
        },
    },
})

export const { setIsBackgroundLoading, storeCurrentTab } = slice.actions

export default slice.reducer

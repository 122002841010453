const windowGlobal = typeof window !== 'undefined' && window

export const tokenPaths = {
    emailVerifyNotify: 'emailVerifyNotify',
    authToken: 'authToken',
    authTokenRevisor: 'authTokenRevisor',
    authTokenAdmin: 'authTokenAdmin',
    consentCookies: 'consentCookies',
    useBareFeatures: 'useBareFeatures',
    lastLogCalculation: 'lastLogCalculation',

    currentTab: 'currentTab',
    isBackgroundLoading: 'isBackgroundLoading',
}

export const getItem = (path: string) => windowGlobal?.localStorage?.getItem(path)

export const setItem = (path: string, item: string) =>
    windowGlobal?.localStorage?.setItem(path, item)

export const removeItem = (path: string) => windowGlobal?.localStorage?.removeItem(path)
